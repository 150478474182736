html {
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body {
    margin: 0;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
}

::-moz-selection {
    text-shadow: none;
    background: #fcfcfc;
    background: rgba(255, 255, 255, .2);
}

::selection {
    text-shadow: none;
    background: #fcfcfc;
    background: rgba(255, 255, 255, .2);
}

img::selection {
    background: 0 0;
}

img::-moz-selection {
    background: 0 0;
}

body {
    webkit-tap-highlight-color: rgba(255, 255, 255, .2);
}

p {
    margin: 0;
}

/* Space out content a bit */
body {
    background-color: #F54E7D;
    color: #FFFFFF;
    font-size: 14px;
    -webkit-touch-callout: none;
    user-select: none;
    overflow: hidden;
}

html, body {
    overflow: hidden;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

/* Old: From Bootstrap */
.brand-heading {
    margin: 0;
    text-transform: uppercase;
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 40px;
}

/* Old: From Bootstrap */
.intro-text {
    font-size: 18px;
}

/* Old: From Bootstrap */
.light {
    font-weight: 400;
}


/* NEW CSS FROM HERE */

#root {
    height: 100%;
}

.App {
    height: 100%;
}

.button {
    /* Shape */
    padding: 7px 16px;
    border-radius: 35px;
    /* Colors */
    border: 2px solid #fff;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    /* Text */
    font-size: 7vw;
    /* Align content */
    display: flex;
    align-items: center;
    justify-content: center;
}

.button .icon {
    width: 7vw;
    height: 7vw;
}


/* Make buttons's text relative to viewport height on landscape */
@media (orientation: landscape) {
    .button {
        font-size: 5vh;
    }
    .button .icon {
        width: 5vh;
        height: 5vh;
    }
}

.button:hover {
    background-color: rgba(255, 255, 255, 0.25);
    color: #fff;
    cursor: pointer;
}

.section footer {
    padding-bottom: 1vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2vh;
}

/* Main Section */

.section-main {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.section-main header {
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Summary Section */

.section-summary {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.section-summary .results {
    padding-top: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*  --- Daily summary specific */
.section-summary .daily-result {
    margin-top: 10vh;
    font-size: 3vh;
}

.section-summary .share {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2vh;
    font-size: 4vh;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
.section-summary .share .actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2vw;
}

.section-summary dialog {
    width: 80%;
}
.section-summary dialog::backdrop {
    background-image: linear-gradient(
            45deg,
            red,
            #F54E7D
    );
    opacity: 0.50;
}

.section-summary dialog div {
    display: flex;
    flex-direction: column;
}

.section-summary dialog div button {
    align-self: flex-end;
}

/* Play Section */

.section-play {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-play .score {
    /* Design */
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid #444;
    padding: 0 10px;
    border-radius: 5px;
    margin: 1vh;
    font-size: 4vh;
    letter-spacing: 1px;
    /* Position */
    align-self: flex-end;
}

.section-play .operation {
    /* Layout */
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 2vw;
    /* Text */
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: 8vw;
    font-weight: 700;
}

.section-play .operation .operand-left {
    flex-grow: 1;
    flex-basis: 0; /* Do not grow from content size but from 0 */
    text-align: right;
}

.section-play .operation .operand-right {
    /* Layout */
    flex-grow: 1;
    flex-basis: 0; /* Do not grow from content size but from 0 */
    text-align: left;
    /* Design */
    text-decoration: underline;
    color: rgba(255, 255, 255, 0.6);
}

.section-play .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 400px;
    flex-grow: 1; /* Setting flex-grow to 1 to make it grow to fill the space */
    padding-bottom: 2vh; /* Adding some air below the numpad */
}

.section-play #timer {
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    width: 85%;
}

.section-play #percentage {
    display: flex;
    justify-content: flex-end;
    /* Old */
    height: 100%;
    width: 100%;
    background-color: white;
}

.section-play #counter {
    font-size: 9px;
    color: grey;
    padding-right: 5px;
}

.section-play .numpad {
    max-width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    flex-grow: 1; /* Setting flex-grow to 1 to make it grow to fill the space */
    align-items: stretch; /* Make the height stretch to fill the space */
}

.section-play .numpad .button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.25);
    border: 2px solid rgba(255, 255, 255, 0.9);
    font-size: 5vh;
    border-radius: 15px;
    /* Get items to wrap every third element because flex-basis 30%.*/
    flex: 1 0 30%;
    /* Reset height, which was being set by .button */
    height: auto;
}

@media (pointer: fine) {
    .section-play .numpad .button:hover {
        background-color: rgba(255, 255, 255, 0.50);
        /* Old */
        color: #FFF;
        cursor: pointer;
    }
}

.section-play .numpad .button:active {
    /* Old */
    background-color: rgba(255, 255, 255, 0.50);
}

.score-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.score-notice {
    font-size: 4vh;
    color: #fff;
}

/* Utility Classes */

.hidden { /* Hides an element, used to hide scoring notice by default until needed */
    display: none;
}
